import Vue from "vue";
import axios from "axios";
import { recordModalAndPage } from "@/assets/recordModalAndPage";

const blobAsDataUrl = (blob) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (error) => {
      reader.abort();
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};

export const ui = {
  namespaced: true,
  state: {
    modalData: {},
    isDrought: false,
    recentActivity: [],
    refreshRows: "",
    notesAdded: false,
    actionModalTemplate: "",
    users: [],
  },
  actions: {
    openModal(context, { data, ui, type, modalId }) {
      context.commit("setModalData", data);
      const modal = modalId
        ? modalId
        : recordModalAndPage({ type }).modal;
      ui.$bvModal.show(modal);
    },

    openNotesModal(context, {ui, modalId }) {
      ui.$bvModal.show(modalId);
    }
  },
  mutations: {
    setModalData(state, data) {
      state.modalData = data;
    },
    setActionModalTemplate(state, data) {
      state.actionModalTemplate = data;
    },
    setIsDrought(state, data) {
      state.isDrought = data;
    },
    setRecentActivity(state, data) {
      state.recentActivity = data;
    },
    setNotesAdded(state, data) {
      state.notesAdded = data;
    },
    setRefreshRows(state, data) {
      state.refreshRows = "";
      Vue.nextTick(() => {
        state.refreshRows = data;
      });
    },
    hidePDFModal(state) {
      state.pdfModal = false;
    },
    async viewPDF(state, data) {
      if (data && data.startsWith("http")) {
        let url = data;
        let pdfFile;
        // Create an axios instance without authorization header to get aws s3 files
        if (data.startsWith("https://s3")) {
          const awsAxios = axios.create({
            transformRequest: (data, headers) => {
              // Remove just the auth header
              delete headers.common.Authorization;
            },
          });
          //Load pdf file
          pdfFile = await awsAxios.get(url, {
            responseType: "arraybuffer",
          });
        } else {
          //Load pdf file
          pdfFile = await axios.get(url, {
            responseType: "arraybuffer",
          });
        }

        const blobPDF = new Blob([pdfFile.data], { type: "application/pdf" });
        const base64PDF = await blobAsDataUrl(blobPDF);
        state.pdfUrl = base64PDF;
      } else {
        state.pdfUrl = data;
      }
      state.pdfModal = true;
    },
  },
};
