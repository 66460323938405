import Vue from "vue";
import VueRouter from "vue-router";
import getEnv from '@/utils/env'
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/billAdjustments",
  },
  {
    path: "/signIn",
    name: "signIn",
    component: () => import("@/views/auth/SignIn"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/Register"),
  },
  {
    path: "/confirm_registration/:token",
    name: "confirmRegistration",
    component: () => import("@/views/auth/ConfirmRegistration"),
  },
  {
    path: "/reset_password/:token?",
    name: "resetPassword",
    component: () => import("@/views/auth/ResetPassword"),
  },
  {
    path: "/disabled",
    name: "disabled",
    component: () => import("@/views/Disabled"),
  },
  {
    path: "/",
    name: "root",
    redirect: { name: "signIn" },
    component: () => import("@/views/Layout"),
    children: [
      {
        path: "/billAdjustments",
        name: "billAdjustments",
        component: () => import("@/views/leftNav/BillAdjustments"),
      },
      {
        path: "/accountLookup",
        name: "accountLookup",
        component: () => import("@/views/leftNav/AccountLookup"),
      },
    ],
  },
  {
    path: "*",
    name: "notFound",
    component: () => import("@/views/PageNotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: getEnv('BASE_URL'),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "signIn",
    "register",
    "confirmRegistration",
    "resetPassword",
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = store.state.auth.user;
  // trying to access a restricted page + not logged in
  // redirect to signIn page
  if (authRequired && !loggedIn) {
    next("/signIn");
  } else {
    next();
  }
});

export default router;
