import "./plugins/bootstrap-vue";

import App from "./App.vue";
import Vue from "vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    notify(variant = null, title = "", content = " ") {
      this.$bvToast.toast(content, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    capitalize(s = '') {
      if (typeof s === 'string') return s.toLowerCase().replace(/\b./g, a => a.toUpperCase());
      return s + '';
    }
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
