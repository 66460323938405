import axios from "axios";
import getEnv from '@/utils/env'

const API_URL = getEnv('VUE_APP_AUTH_SERVER');

export function login(user) {
  // POST {username, password} & save JWT to Local Storage
  return axios
    .post(`${API_URL}/login`, {
      email: user.email,
      password: user.password,
    })
    .then(({ data }) => {
      if (data.token) {
        data.email = user.email;
        localStorage.setItem("user", JSON.stringify(data));
      }
      return data;
    }).catch((error) => {
      return Promise.reject(error.response);
    });
}

export function logout() {
  // remove JWT from Local Storage
  localStorage.removeItem("user");
}

export function register(user) {
  return axios.post(`${API_URL}/register`, user);
}

export function confirm_registration(token) {
  return axios.post(`${API_URL}/confirm_registration/${token}`);
}

export function reset_password(token, password) {
  return axios.post(`${API_URL}/reset_password/${token}`, { password });
}

export function reset_password_request(email) {
  return axios.post(`${API_URL}/reset_password_request`, { email });
}
