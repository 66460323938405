<template>
  <div id="app" :class="{ mnwdColorBackground: isAuthPage }">
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    routeName() {
      return this.$route.name;
    },

    isAuthPage() {
      return (
        this.routeName === "signIn" ||
        this.routeName === "register" ||
        this.routeName === "resetPassword"
      );
    },
  },
};
</script>

<style lang="scss">
@import "./assets/mnwd.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto%20Condensed&display=swap");
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
}
</style>
